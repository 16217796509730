import React, {useEffect} from "react"
import gsap from "gsap";

const PaypalIcon = (props: React.SVGProps<SVGSVGElement> & {hover: boolean}) => {
    useEffect(() => {
        gsap.to('.p1', {duration: 0.5, x: props.hover ? -1 : 0, y: props.hover ? -1 : 0})
        gsap.to('.p2', {duration: 0.5, x: props.hover ? 1 : 0, y: props.hover ? 1 : 0})
    }, [props.hover])
    return (
        //@ts-ignore
            <svg
                width={31}
                height={35}
                viewBox={'-5 -5 31 35'}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g >
                    <path
                        className='p1'
                        d="M17.294 2.1C16.193.837 14.204.296 11.658.296H4.27c-.252 0-.496.09-.687.255a1.066 1.066 0 0 0-.358.643L.15 20.82a.637.637 0 0 0 .626.738h4.561l1.145-7.31-.035.23c.082-.517.52-.898 1.04-.898h2.168c4.258 0 7.591-1.74 8.565-6.772.029-.15.054-.294.076-.436-.123-.065-.123-.065 0 0 .29-1.86-.002-3.126-1.002-4.272Z"
                        fill="#27346A"
                    />
                    <path
                        className='p1'
                        d="M8.228 5.702a.922.922 0 0 1 .399-.09h5.791c.686 0 1.326.044 1.91.14a7.99 7.99 0 0 1 1.167.28c.287.096.555.209.8.34.29-1.86-.001-3.126-1.002-4.272-1.1-1.263-3.09-1.804-5.635-1.804H4.27c-.52 0-.963.381-1.044.898L.15 20.82a.636.636 0 0 0 .626.737h4.561l2.376-15.16a.934.934 0 0 1 .515-.695Z"
                        fill="#27346A"
                    />
                    <path
                        className='p2'
                        d="M18.22 6.808c-.974 5.032-4.307 6.772-8.565 6.772H7.487c-.52 0-.96.381-1.04.898l-1.425 9.088a.557.557 0 0 0 .548.645h3.844a.922.922 0 0 0 .914-.785l.037-.197.725-4.62.046-.256a.932.932 0 0 1 .914-.785h.575c3.724 0 6.64-1.522 7.493-5.924.355-1.84.171-3.376-.77-4.455a3.677 3.677 0 0 0-1.052-.817c-.023.143-.047.287-.076.436Z"
                        fill="#2790C3"
                    />
                    <path
                        className='p2'
                        d="M17.276 5.963a7.07 7.07 0 0 0-.46-.118 8.622 8.622 0 0 0-.487-.093 11.947 11.947 0 0 0-1.91-.14H8.626a.924.924 0 0 0-.913.786l-1.23 7.85-.036.23c.08-.517.52-.898 1.04-.898h2.168c4.257 0 7.59-1.74 8.565-6.772.029-.15.053-.294.075-.436a5.242 5.242 0 0 0-1.02-.409"
                        fill="#1F264F"
                    />
                </g>
            </svg>

    )

}
export default PaypalIcon
