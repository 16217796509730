import React, {useEffect, useRef, useState} from "react";
import './DonationForm/widget.css';
import {withNamespaces} from 'react-i18next';
import PaypalButton from "../../PaypalButton";
import {useBucket} from "./hooks/useBucket";
import CryptoIcon from "./CryptoIcon";
import SelectedCryptoCurrencyView from "./crypto/SelectedCryptoCurrencyView";
import CryptoOptionsView from "./crypto/CryptoOptionsView";
import AnimationSection from "./animation/AnimationSection";
import getTotalAmount from "./utils/getTotalAmount";

const currenciesReduceOptions = (acc, val) => {
    if (Array.isArray(val) && val.length === 3) {
        const [value, label, char] = val;
        return [...acc, {value, label, char}]
    }
    return acc;
};

const tipsReduceOptions = (acc, val) => {
    const [value, label] = val;
    return [...acc, {value, label}]
}


const DonationFormV2 = ({
                            t,
                            lng,
                            title = t("donation_form_title"),
                            subtitle = t("donation_form_subtitle"),
                            progressBarCollected = t("progress_bar_collected"),
                            progressBarOf = t("progress_bar_of"),
                            customPriceLabel = t("donation_form_customPriceLabel"),
                            submit = t("donation_form_submit"),
                            confirmTitle = t('confirm_title'),
                            confirmSubscriptionTitle = t('confirm_subscription_title'),
                            donationForTeam = t('donation_for_team'),
                            donationForTeamText = t('donation_for_team_text'),
                            checkTitle = t('check-title'),
                            checkItemsDonation = t('check-items-donation'),
                            checkItemsHelpDonation = t('check-items-help-bysol'),
                            checkTotal = t('check-total'),
                            confirmButtonSubmitLoading = t('confirm_button_submit_loading'),
                            confirmButtonChangeValue = `←${t('confirm_button_change_value')}`,
                            minimumPriceDonation = t('minimum_input_donation'),
                            totalChargeMonthText = t('total-charge-month-text'),
                            alternativeMethodOrText = t('paypal-or-text'),
                            totalChargeModeSupscription = t('total-charge-mode-supscription'),
                            modePaymentTab = t('mode-payment-tab'),
                            modeSubscriptionTab = t('mode-subscription-tab'),
                            confirmCancelSubscriptionInfoText = t('confirm-cancel-subscription-info-text'),
                            teamTipsOtherAmount = t('team_tips_other_amount'),
                            teamTipsSupportText = t('team_tips_support_text'),
                            teamTipsProposal = t('team_tips_proposal'),
                            teamTipsAmount = t('team_tips_amount'),
                            donationAmount = t('donation_amount'),
                            selectCryptoText = t('select_crypto'),
                            cryptoWalletText = t('crypto_wallet'),
                            copyToClipboardText = t('copy_to_clipboard'),
                            copiedToClipboardText = t('copied_to_clipboard'),
                            returnBackText = `←${t('return_back')}`,
                            selectedTipsIndex = 2,
                            paypal = '1',
                            currencies = [
                                ['eur', t("curr_eur"), '€'],
                                ['usd', t("curr_usd"), '$'],
                                ['pln', t("curr_pln"), 'zł'],
                                ['czk', t("curr_czk"), 'Kč'],
                                ['uah', t("curr_uah"), '₴'],
                                ['gbp', t("curr_gbp"), '£'],
                                ['chf', t("curr_chf"), '₣'],
                                ['aud', t("curr_aud"), 'AU$'],
                                ['cad', t("curr_cad"), 'CA$'],
                                ['rub', t("curr_rub"), '₽']
                            ],
                            cryptoCurrencies = [ // code,
                                ['btc', 'btc address', 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Totally_not_a_Rickroll_QR_code.png'],
                                ['eth', 'btc address', 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Totally_not_a_Rickroll_QR_code.png'],
                                ['ltc', 'btc address', 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Totally_not_a_Rickroll_QR_code.png'],
                                ['xmr', 'btc address', 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Totally_not_a_Rickroll_QR_code.png'],
                                ['usdc (eth)', 'btc address', 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Totally_not_a_Rickroll_QR_code.png'],
                                ['usdt eth', 'btc address', 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Totally_not_a_Rickroll_QR_code.png'],
                                ['busd (bep20)', 'btc address', 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Totally_not_a_Rickroll_QR_code.png'],
                                ['usdt trx', 'btc address', 'https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg', 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Totally_not_a_Rickroll_QR_code.png'],
                            ],

                            tips = [
                                [0],
                                [5, "5%"],
                                [10, "10%"],
                                [15, "15%"],
                                [20, "20%"],
                                [25, "25%"],
                                [30, "30%"],
                            ],

                            definedPrices = [20, 50, 75, 100, 150, 200, 300, 500],

                            minimalPrice = 5,

                            onPriceSubmit = () => null,
                            ...props
                        }) => {
    const {
        mbucket,
        modeDefault,
        subOnly,
        showPB,
        showDescription,
        withSub: withSubParam,
        disable_tips = 0,
    } = props;

    const withSub = !!Number(withSubParam)

    selectedTipsIndex = Number(selectedTipsIndex)

    const [modePayment, setModePayment] = useState("payment"); // payment || subscription

    const [minimalValueCurrency, setMinimalValueInCurrency] = useState(minimalPrice);

    const [donation, setDonation] = useState(0); // сам донат
    const [changedDonationInput, setChangedDonationInput] = useState(false);

    const currenciesOptions = currencies.reduce(currenciesReduceOptions, []);
    const tipsOptions = tips.reduce(tipsReduceOptions, []);

    const [currency, setCurrency] = useState(currenciesOptions[0].value);
    const [tipsValue, setTips] = useState(); // чаевые для bysol
    const [otherTipsMode, setOtherTipsMode] = useState(false);
    const [currencyChar, setCurrencyChar] = useState(currenciesOptions[0].char);


    const [viewPreview, setViewPreview] = useState(false);
    const [viewCryptoOptions, setViewCryptoOptions] = useState(false);
    const [viewSelectedCrypto, setViewSelectedCrypto] = useState(null); // stores selected crypto
    const [hoverCryptoIcon, setHoverCryptoIcon] = useState(false); // stores selected crypto
    const bucket = useBucket(mbucket)
    const {address, icon, title: bucketId} = viewSelectedCrypto || {};

    const setClipboard = async (text) => {
        return navigator.clipboard.writeText(text);
    }

    useEffect(() => {
        if (!otherTipsMode) {
            let percent = selectedTipsIndex * 0.05;
            setTips(percent)
        }

    }, [donation])

    const getTipsValue = () => {

        if (otherTipsMode) {
            return tipsValue;
        }

        return donation * tipsValue ? (donation * tipsValue).toFixed(2) : 0;
    }
    const validDonation = donation === true || donation >= minimalValueCurrency;
    const validTips = tipsValue === true || getTipsValue() >= 0;
    const isSubmit = validDonation && validTips;

    const [loading, setLoading] = useState(false);

    if (modeDefault && modePayment !== modeDefault && withSub) {
        setModePayment(modeDefault);
    }

    if (modePayment !== "subscription" && subOnly && !withSub) {
        setModePayment("subscription");
    }

    const onChangeCurrencyList = (e) => {
        const value = e.target.value.split("-")
        const currencyShort = value[0];
        const currencyKey = currencyShort.toUpperCase();

        let minimalValue;
        switch (currencyShort) {
            case "rub":
                minimalValue = 500;
                break;
           case "uah":
                minimalValue = 200;
                break;
            case "czk":
                minimalValue = 125;
                break;
            case "pln":
                minimalValue = 25;
                break;
            case "aud":
                minimalValue = 10;
                break;
            case "cad":
                minimalValue = 10;
                break;
            default:
                minimalValue = 5;
        }

        setMinimalValueInCurrency(minimalValue);

        setCurrency(currencyKey);
        setCurrencyChar(value[1]);
    }

    const parseAmount = (amount) => {
        const amountDigits = amount.toString();
        const numberOfGroups = Math.floor(amountDigits.length / 3);
        const firstGroupLength = amountDigits.length % 3;

        let result = amountDigits.slice(0, firstGroupLength);

        for (let i = 1; i <= numberOfGroups; i++) {
            const start = firstGroupLength + 3 * (i - 1);
            const end = firstGroupLength + 3 * i;
            const group = amountDigits.slice(start, end);

            result += group + " ";
        }
        const arr = result.split("").reverse();

        for (let i = 3; i < arr.length - 1; i+=4) {
            arr[i] = ` ${arr[i]}`;
        }
        result = arr.reverse().join('').replace(/  /g, " ");
        result = result.trimEnd();

        return result;
    }

    const generateOptions = () => {
        let options = [];
        for (let i = 0; i < 7; i++) {
            const percentage = i * 5;
            const calculatedTips = (donation * percentage / 100).toFixed(2);
            // const selected = (percentage / 100).toFixed(2) === tipsValue;

            const option = <option
                selected={i === selectedTipsIndex}
                value={percentage / 100}
                key={i}>
                {percentage}% ({currencyChar} {calculatedTips})
            </option>
            options.push(option);
        }

        const otherOption = <option selected={otherTipsMode} key={7}
                                    value={'other'}>{teamTipsOtherAmount}</option>
        options.push(otherOption)

        return options;
    }

    const onChangeTipsList = (e) => {
        if (e.target.value !== 'other') {
            setTips(parseFloat(e.target.value).toFixed(2));
            setOtherTipsMode(false);
            return;
        }
        setOtherTipsMode(true);
        setTips(0);
    }

    document.onkeydown = function (event) {
        const keyCode = event ? (event.which ? event.which : event.keyCode) : event.keyCode;
        if (keyCode === 13) {
            if (isSubmit) {
                if (viewPreview) {
                    setLoading(true);
                    onPriceSubmit({donation, currency, mode: modePayment});
                } else {
                    setViewPreview(true);
                }
            }
        }
    }

    const selectedCurrency = currenciesOptions.find(cur => cur.char === currencyChar);

    const descriptionPart = showDescription === '1' && (
        <div className="support__confirmation-title" style={{textAlign: 'center', paddingBottom: '15px'}}>
            {subtitle}
        </div>
    );
    const descriptionPartAlways = (
        <div className="support__confirmation-title" style={{textAlign: 'center', paddingBottom: '15px'}}>
            {subtitle}
        </div>
    );

    const previewPart = (
        <div className={"support__controls preview"}>
            <div
                className="support__confirmation-label">{modePayment === 'subscription' ? confirmSubscriptionTitle : confirmTitle}</div>
            <div className="support__confirmation-value">
                <span>{getTotalAmount({
                    donation,
                    tips: getTipsValue(),
                    totalChargeMonthText,
                    currencyChar,
                    isSubscription: modePayment === "subscription",
                    mode: 'html'
                })}</span>
            </div>
            {modePayment === "subscription" && (
                <div className="support__confirmation-notes">
                    <p>* {confirmCancelSubscriptionInfoText}</p>
                </div>
            )}
            <div className="support__confirmation-cancel">
                <img/>
                <button
                    disabled={loading}
                    type="button"
                    onClick={() => {
                        setViewPreview(false);
                    }}
                >
                    {confirmButtonChangeValue}
                </button>
            </div>
        </div>
    )

    const progressBarPart = (
        <div className="support__progress">
            <div className="support__sum">
                {currenciesOptions[0].char} {parseAmount(Math.floor(bucket.value))}
                <span> {progressBarOf} {currenciesOptions[0].char} {parseAmount(bucket.goal)}</span>
            </div>
            <div className="progress">
                <div className="progress__bar">
                    <div style={{width: `${bucket.progress * 100}%`}}/>
                </div>
            </div>
        </div>
    );

    const subscriptionPart = (
        <ul className="support__tabs tabs js-tabs-list">
            <li className="tabs__item">
                <a className={`tab ${modePayment === "subscription" ? "selected__tab" : ""}`}
                   onClick={() => setModePayment("subscription")}>
                    {modeSubscriptionTab}
                </a>
            </li>
            <li className="tabs__item">
                <a
                    className={`tab ${modePayment === "payment" ? "selected__tab" : ""}`}
                    onClick={() => setModePayment("payment")}>{modePaymentTab}
                </a>
            </li>
        </ul>
    );


    const amountInputPart = (
        <div className="support__controls">
            <div className="support__amount-group">
                <label className="custom-select custom-select--currency">
                    <select
                        onChange={onChangeCurrencyList}
                        value={`${selectedCurrency.value}-${selectedCurrency.char}`}
                    >
                        {currenciesOptions.map((cur, index) => {
                            return (
                                <option
                                    selected={cur.char === currencyChar}
                                    key={index}
                                    value={`${cur.value}-${cur.char}`}
                                >
                                    {cur.char} - {cur.label}
                                </option>
                            )
                        })}
                    </select>
                    <div className="custom-select--currency-letter">{selectedCurrency.char}</div>
                    <div className="custom-select--code">{selectedCurrency.value}</div>
                </label>
                <label className="support__amount">
                    <input
                        className={`support__input ${!validDonation && changedDonationInput ? 'support__input--error' : 'support__input--success'}`}
                        type="number"
                        placeholder="0"
                        step="0.01"
                        required
                        inputMode="numeric"
                        value={donation || ''}
                        min={minimalPrice}
                        onChange={(e) => {
                            setDonation(parseFloat(e.target.value) || 0);
                            setChangedDonationInput(true);
                        }}
                    />
                    {(!validDonation && changedDonationInput) &&
                    <span
                        className="invalid-feedback">{minimumPriceDonation} {currencyChar}{minimalValueCurrency}.</span>
                    }
                </label>
            </div>
            {disable_tips !== "1" && (
                <>
                    <div className="support__team">
                        <span className="support__team-title">{teamTipsProposal}</span>
                        <p className="support__team-description">{teamTipsSupportText}</p>
                        <div className={`${otherTipsMode ? 'support__team-change' : ''}`}>
                            <div className={`${otherTipsMode ? 'custom-select-wrapper' : ''}`}>
                                <select className="custom-select tips" onChange={onChangeTipsList}>
                                    {
                                        generateOptions()
                                    }
                                </select>
                            </div>
                            {otherTipsMode &&
                            <label className="support__amount">
                                <input
                                    inputMode="numeric" value={tipsValue || ""}
                                    className={`support__input ${!validTips ? "support__input--error" : "support__input--success"}`}
                                    type="number"
                                    placeholder="0"
                                    step="0.01"
                                    min={0}
                                    onChange={(e) => {
                                        setTips(parseFloat(e.target.value) || 0);
                                    }}
                                />
                                <span className="support__label">{currencyChar}</span>
                            </label>
                            }
                        </div>

                        <div className="support__team-sum">
                            <div className="support__team-sum-item">
                                <span>{donationAmount}</span>
                                <span>{currencyChar} {donation}</span>
                            </div>
                            <div className="support__team-sum-item">
                                <span>{teamTipsAmount}</span>
                                <span>{currencyChar} {getTipsValue()}</span>
                            </div>
                        </div>
                    </div>
                    <div className="support__total">
                        <div className="support__total-sum">
                            <span>{checkTotal}</span>
                            <span>
                     {getTotalAmount({
                         donation,
                         tips: getTipsValue(),
                         totalChargeMonthText,
                         currencyChar,
                         isSubscription: modePayment === "subscription"
                     })}
                  </span>
                        </div>
                    </div>
                </>
            )}

            {
                modePayment === "subscription" &&
                <div className={"support__confirmation-notes"}>
                    * {confirmCancelSubscriptionInfoText}
                </div>
            }
        </div>
    );

    const submitPart = (
        <div className="support__submit">
            <button
                className={`button button--l button--block ${!isSubmit || loading ? 'button--disabled' : 'button--primary'}`}
                disabled={!isSubmit || loading}
                onClick={viewPreview
                    ? () => {
                        setLoading(true);
                        // const amount = parseAmount(parseFloat(donation) + parseFloat(getTipsValue()))


                        let donationParams = {
                            donation: parseFloat(donation),
                            currency,
                            mode: modePayment
                        };

                        const tipsValue = parseFloat(getTipsValue());
                        if (!isNaN(tipsValue) && tipsValue > 0) {
                            donationParams.support_team = {
                                amount: tipsValue * 100
                            }
                        }

                        onPriceSubmit(donationParams);
                    }
                    : () => setViewPreview(true)
                }
            >
                <span>{loading ? confirmButtonSubmitLoading : submit}</span>
            </button>
            <div className="support__footer" style={{width: '100%'}}>
                <img
                    src="/img/stripe-card.png"
                    width="90"
                    height="29"
                    alt=""
                />
            </div>
        </div>
    );

    const alternatePaymentPart = (
        <div className={`${bucket.isCrypto ? "alternative-methods" : "alternative-methods-no-crypto"}`}>
            <div className="alternative-methods__or-wrapper">
                <span className="alternative-methods__or">{alternativeMethodOrText}</span>
            </div>

            <div className={`${bucket.isCrypto ? "alternative-methods__button" : ''}`}>
                <PaypalButton isImage={!bucket.isCrypto} lng={lng} name={title} bucket={mbucket}/>
            </div>
            {bucket.isCrypto && (
                <div className="alternative-methods__button">
                    <button
                        onClick={() => setViewCryptoOptions(true)}
                        onMouseEnter={() => setHoverCryptoIcon(true)}
                        onMouseLeave={() => setHoverCryptoIcon(false)}
                        className="button button--l button--block button--outlined">
                        <CryptoIcon hover={hoverCryptoIcon}/>
                        <div className={'text'}>Crypto</div>
                    </button>
                </div>
            )}

        </div>
    );

    return (
        <div className={'wrapper'}>

            <AnimationSection
                visible={viewSelectedCrypto}
                className="support cryptocurrency-selected"
                style={{width: '100%'}}>
                {descriptionPartAlways}
                <SelectedCryptoCurrencyView {...{
                    address,
                    addressTitle: cryptoWalletText,
                    copiedToClipboardText,
                    icon,
                    bucketId,
                    setClipboard,
                    copyToClipboardText,
                    setViewSelectedCrypto,
                    returnBackText,
                }}/>
            </AnimationSection>

            <AnimationSection visible={viewCryptoOptions && !viewSelectedCrypto} isHide={viewSelectedCrypto}
                              className="support cryptocurrency-list" style={{width: '100%'}}>
                {showDescription === "1" && descriptionPart}
                <CryptoOptionsView {...{
                    descriptionPart: descriptionPartAlways,
                    selectCryptoText: `${selectCryptoText}:`,
                    bucket,
                    setViewSelectedCrypto,
                    returnBackText,
                    setViewCryptoOptions
                }}/>
            </AnimationSection>
            <AnimationSection visible={!(viewCryptoOptions || viewPreview)} isHide={viewCryptoOptions || viewPreview}
                              className="support" style={{width: '100%'}}>
                {descriptionPart}
                {showPB !== '0' && progressBarPart}
                {withSub && subscriptionPart}
                {amountInputPart}
                {submitPart}

                {paypal === '1' && alternatePaymentPart}
            </AnimationSection>

            <AnimationSection
                visible={viewPreview}
                className={'confirmation-popup'}
            >
                {descriptionPartAlways}
                {previewPart}
                {submitPart}
            </AnimationSection>

        </div>

    )
}

export default withNamespaces()(DonationFormV2);
