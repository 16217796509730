import React, {useContext, useEffect, useState} from 'react'

const StripeContext = React.createContext({
    client: null,
})

export const StripeProvider = (props) => {
    const [client, setClient] = useState(null);
    useEffect(() => {
        setClient(window.Stripe(process.env.REACT_APP_STRIPE_KEY))
    }, [])
    return <StripeContext.Provider value={{
        client
    }} {...props}/>
}

const useStripe = () => useContext(StripeContext);
export default useStripe
