import React from "react";
//@ts-ignore
import styled from "styled-components";

const Wrapper = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.25s ${(p: AnimationSection) => p.visible ? 'ease-in' : 'ease-out'};
  transition-delay: ${(p: AnimationSection) => p.visible ? ' 0.15s' : '0'};
  opacity: ${(p: AnimationSection) => p.visible ? 1 : 0};
  transform: translateX(${(p: AnimationSection) => p.visible ? (p.isHide ? '30px' : 0) : (p.isHide ? '-30px' : '30px')});
  
  pointer-events: ${(p: AnimationSection) => p.visible ? 'all' : 'none'};
`

interface AnimationSection {
    children: React.ReactNode
    visible: boolean
    isHide: boolean
}

const AnimationSection = ({children, ...props}: AnimationSection) => {

    return (
        <Wrapper className={'animation-section'} {...props}>
            {children}
        </Wrapper>
    )
}

export default AnimationSection;