import React, {ReactNode, useContext, useEffect, useState} from "react";
import firebaseService from "../../../../utils/firebase";
import {Selector} from 'reselect'
import filterObjectCollection from "../../../../utils/filterObjectCollection";


interface CryptoCurrency {
    id: string
    title: string
    enabled: boolean
    sort: number
    address: string
    icon: string
}

interface CryptoCurrencies {
    [key: string]: CryptoCurrency
}


interface Bucket {
    goal: number
    value: number
    cryptoCurrencies: string[]
    crypto: {
        [key: string]: string
    }
}

export interface BucketContextValues extends Omit<Bucket, 'crypto' | 'cryptoCurrencies'> {
    loading: boolean
    isCrypto: boolean
    progress: number
    isGoal: boolean
    enabledCryptos: CryptoCurrencies
    cryptoCurrencies: CryptoCurrency[] | null
}

export const BucketContext = React.createContext<BucketContextValues>({
    goal: 0,
    value: 0,
    progress: 0,
    isCrypto: false,
    isGoal: false,
    loading: true,
    enabledCryptos: {},
    cryptoCurrencies: []
})

interface BucketProviderProps {
    bucketName: string
    children?: ReactNode
}

interface IBucketSelectorProps {
    loading: boolean
    cryptoBalance: number
    bucketValue: BucketValue | null,
    cryptoCurrencies: CryptoCurrencies | null
}

const selectBucketContextProps: Selector<IBucketSelectorProps, BucketContextValues> = (state: IBucketSelectorProps): BucketContextValues => {
    const bucket = state.bucketValue;
    const isGoal = bucket ? !isNaN(bucket.goal) && bucket.goal > 0 : false;

    const value = (bucket?.value || 0) + state.cryptoBalance;
    return bucket ? {
        loading: state.loading,
        goal: isGoal ? bucket.goal : 0,
        isGoal,
        value,
        progress: isGoal ? value / bucket.goal : 0,
        isCrypto: bucket.cryptoCurrencies ? Object.keys(bucket.cryptoCurrencies)?.length > 0 : false,
        cryptoCurrencies: bucket.cryptoCurrencies ? Object.values(bucket.cryptoCurrencies).sort(
            (a, b) => b.sort - a.sort
        ) : [],
        enabledCryptos: state.cryptoCurrencies || {}
    } : {
        loading: true,
        goal: 0,
        isGoal: false,
        value: 0,
        progress: 0,
        isCrypto: false,
        cryptoCurrencies: [],
        enabledCryptos: {}
    }
}

interface BucketValue extends Omit<Bucket, 'cryptoCurrencies'> {
    cryptoCurrencies: CryptoCurrencies
}

type BucketValueState = null | (BucketValue & BucketBalances)

type BucketBalances = {
    balances: {
        [key: string]: number
    }
} | undefined

export const useCryptoCurrenciesBalance = (bucket: BucketBalances) => {
    //get crypto currencies from realtime crypto/currencies
    const [exchangeRates, setExchangeRates] = useState<{ [key: string]: number } | null>(null);
    const [balance, setBalance] = useState(0);
    useEffect((): any => {
        return firebaseService.getCryptoExchangeRates(setExchangeRates);
    }, []);

    useEffect(() => {
        if (bucket?.balances && exchangeRates) {
            const cryptoCurrencies = Object.keys(bucket.balances);
            const total = cryptoCurrencies.reduce(
                (total, currency) => {
                    const balance = bucket.balances[currency];
                    const exchangeRate = exchangeRates[currency]
                    return total + (balance * exchangeRate);
                }, 0
            )
            setBalance(total);
        }
    }, [bucket?.balances, exchangeRates])


    return balance;
}

export const BucketProvider = ({children, bucketName}: BucketProviderProps) => {
    const cryptoCurrencies = useCryptocurrencies();
    const [bucketValue, setBucket] = useState<BucketValueState>(null);
    const [loading, setLoading] = useState(true);
    const cryptoBalance = useCryptoCurrenciesBalance(bucketValue as BucketBalances);

    useEffect(() => {
        const isLoading = bucketValue == null || cryptoCurrencies == null;
        setLoading(isLoading);
    }, [bucketValue, cryptoCurrencies])


    useEffect(() => {
        if (bucketName && cryptoCurrencies) {
            return firebaseService.createBucketDataSubscription(bucketName, cryptoCurrencies, setBucket)
        }
    }, [bucketName, cryptoCurrencies])
    const value = selectBucketContextProps({
        bucketValue,
        cryptoBalance,
        cryptoCurrencies, loading
    });
    return (
        // @ts-ignore
        <BucketContext.Provider
            value={value}>
            {children}
        </BucketContext.Provider>
    );


}


const useCryptocurrencies = (): CryptoCurrencies | null => {
    const [cryptoCurrencies, setCryptoCurrencies] = useState<CryptoCurrencies | null>(null);
    useEffect((): any => {
        const db = firebaseService.db;
        return db?.ref('crypto_currencies').once('value').then(
            (s: any) => {
                const onlyEnabled = filterObjectCollection<Omit<CryptoCurrency, 'id'>>(
                    (crypto) => crypto.enabled, s.val()
                )
                setCryptoCurrencies(onlyEnabled)
            }
        )
    }, []);
    return cryptoCurrencies;
}

export const useBucket = (): BucketContextValues => {
    return useContext(BucketContext);
}
