import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import {withNamespaces} from 'react-i18next';

const withTranslation = withNamespaces();


export const SubscriptionContext = React.createContext<{
    subscribeUser: boolean,
    setSubscribeUser: (value: boolean) => void
    saveLocalState: () => Promise<void>,
    isOpenModal: boolean,
    setOpenModal: (value: boolean) => void
    onComplete: ((subscribed: boolean) => void) | null,
    setOnComplete: (value: () => void) => void,
    subscribed: boolean | null,
}>({
    subscribeUser: false,
    isOpenModal: false,
    subscribed: null,
    saveLocalState: async () => {},
    setSubscribeUser: () => {
        throw new Error('setSubscribeUser function must be overridden')
    },
    setOpenModal: (value: boolean) => {
        throw new Error('showModal function must be overridden')
    },
    onComplete: null,
    setOnComplete: (value: any) => {
        throw new Error('setOnComplete function must be overridden')
    }

})

type LocalState = {
    subscribed: boolean
};
export const SubscriptionProvider = ({children}: any) => {
    const [subscribeUser, setSubscribeUser] = useState(true);
    const [subscribed, setSubscribed] = useLocalState<LocalState>('bsl', {subscribed: null});
    const [isOpenModal, setOpenModal] = useState(false)
    const [onComplete, setOnComplete] = useState<((subscribed: boolean) => void) | null>(null)
    return (
        <SubscriptionContext.Provider value={{
            subscribeUser,
            isOpenModal,
            setOpenModal,
            onComplete,
            setOnComplete,
            saveLocalState: async () => {
                // @ts-ignore
                await setSubscribed({subscribed: subscribeUser})
            },
            setSubscribeUser: setSubscribeUser,
            // @ts-ignore
            subscribed: subscribed.subscribed,
        }}>
            {children}
        </SubscriptionContext.Provider>
    )
}


const useLocalState = <T extends object>(key: string, defaultValue: object) => {
    const [value, setValue] = React.useState<T>(() => {
        const localValue = window.localStorage.getItem(key)
        return localValue ? JSON.parse(localValue) : defaultValue
    });
    const setLocalValue = (newValue: any) => {
        return window.localStorage.setItem(key, JSON.stringify(newValue))
    }
    return [value, setLocalValue]
}

export const useOpenSubscriptionModal = () => {
    const {setOpenModal, setOnComplete, subscribed} = useContext(SubscriptionContext);
    return (onComplete: (subscribe: boolean) => void) => {
        if(subscribed === null) {
            setOnComplete(() => (subscribe: boolean) => {
                onComplete(subscribe)
            })
            setOpenModal(true)
        }else{
            onComplete(subscribed);
        }

    }
}

interface SubscriptionProps {
    modal: { open: boolean };
    checkbox: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    subscribe:  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
}

const useSubscriptionsProps = (): SubscriptionProps => {

    const {subscribeUser, setSubscribeUser, saveLocalState, isOpenModal, setOpenModal, onComplete} = useContext(SubscriptionContext)
    return {
        modal: {
            open: isOpenModal,
        },
        checkbox: {
            checked: !subscribeUser,
            onChange: (e) => {
                setSubscribeUser(!e.target.checked)
            }
        },
        subscribe: {
            className: 'button button--l button--block button--primary',
            onClick: async () => {
                // @ts-ignore
                setOpenModal(false)
                await saveLocalState()
                onComplete && onComplete(true)
            }
        }
    }
}

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  opacity: ${(props: { open: boolean }) => (props.open ? 1 : 0)};
  pointer-events: ${(props: { open: boolean }) => (props.open ? 'auto' : 'none')};
  transition: opacity 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: #fff;
  border: 1px solid #ddd;
  max-width: 500px;
  width: 95%;
  overflow: hidden;
`

const ButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  //   horizontal
  flex-direction: column;
  gap: 2rem;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`

const Title = styled.div`
  font-size: 1.5rem;
  color: #000;
  font-weight: 600;
  margin-bottom: 1rem;
`

const Description = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 2rem;
`

const ChexboxWrapper = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

export const SubscriptionModal = withTranslation(({t}: any) => {
    const {modal, subscribe, checkbox} = useSubscriptionsProps()
    return (
        <Modal {...modal}>
            <Wrapper>
                <Description>{t("subscription_subtitle")}</Description>
                <ButtonsWrapper>
                    <ChexboxWrapper>
                    <input id={'not-subscribe'} type={"checkbox"} title={t('subscription_checkbox')} {...checkbox}/>
                        {/*@ts-ignore*/}
                        <label for={'not-subscribe'}>{t('subscription_checkbox')}</label>
                    </ChexboxWrapper>
                    <button {...subscribe}>{t('subscription_subscribe')}</button>
                </ButtonsWrapper>
            </Wrapper>

        </Modal>
    )
})
