import firebase from "firebase";


class FirebaseService {
    constructor() {
        // const e = process.env;
        const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
        console.log(firebaseConfig)
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
            this.db = firebase.database();
        }

        // this.initAppCheck()
    }

    initAppCheck = () => {
        const appCheck = firebase.appCheck();
        // alert(process.env.REACT_APP_RECAPTCHA_PUBLIC)
        appCheck.activate(
            process.env.REACT_APP_RECAPTCHA_PUBLIC || '',
            // Optional argument. If true, the SDK automatically refreshes App Check
            // tokens as needed.
            true);
    }

    get recaptcha() {
        return window.grecaptcha;
    }

    activateRecaptcha = () => {
        return new Promise(
            (res, rej) => {
                const grecaptcha = window.grecaptcha
                grecaptcha.ready(function () {
                    grecaptcha.execute()
                        .then(function (token) {
                            // console.log(token)
                            res(token);
                        })
                        .catch(e => {
                            alert(e.message);
                            rej(e)
                        })
                })
            }
        )
    }

    calculateCryptoCurrencies = (cryptoInfo, bucket) => {
        if(bucket.cryptoCurrencies && bucket.cryptoCurrencies.length > 0){
            return bucket.cryptoCurrencies.reduce((cryptos, rawId) => {

                const id = rawId.replace("-", "_");
                const isEnabled = !!cryptoInfo[id];
                if(isEnabled){
                    return {
                        [rawId]: {
                            id: rawId,
                            title: cryptoInfo[id].title,
                            address: bucket.crypto[id],
                            sort: cryptoInfo[id].sort,
                            icon: cryptoInfo[id].icon.src
                        },
                        ...cryptos
                    }
                }
                return cryptos;
            }, {})
        }
        return null;
    }

    createBucketDataSubscription = (bucket, cryptoCurrencies,  onResponse) => {
        return firebase.firestore().doc(`buckets/${bucket}`).onSnapshot( (s) => {
                const bucketsData = s.data();
                if (bucketsData) {
                    if(bucketsData.cryptoCurrencies && bucketsData.cryptoCurrencies.length > 0 && cryptoCurrencies){
                        bucketsData.cryptoCurrencies = this.calculateCryptoCurrencies(cryptoCurrencies, bucketsData)
                    }
                    onResponse(bucketsData);
                }
            });
    }

    getCryptos(update){
        firebase.database().ref('crypto_currencies').once('value').then(
            (s) => {
                update(s.val())
            }
        )
    }

    getCryptoExchangeRates(update){
        return firebase.database().ref('crypto/currencies').once('value').then(
            (s) => {
                update(s.val())
            }
        )
    }

}

const firebaseService = new FirebaseService();
export default firebaseService;
