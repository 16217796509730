import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationRU from './translates/ru.json';
import translationEN from './translates/en.json';
import translationFI from './translates/fi.json';
import translationNL from './translates/nl.json';
import translationBY from './translates/by.json';

export const supportedLang = [
  "ru",
  "by",
  "en",
  "nl",
  "fi"
]

// the translations
const resources = {
  ru: {
    translation: translationRU
  },
  by: {
    translation: translationBY
  },
  en: {
    translation: translationEN
  },
  fi: {
    translation: translationFI
  },
  nl: {
    translation: translationNL
  }
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources,
    lng: supportedLang,
    fallbackLng: "en",
    simplifyPluralSuffix: false,
  });

export default i18n;
