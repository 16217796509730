import * as React from 'react';
import {
    useRef, useState
} from 'react';
import PaypalIcon from "./StripeElementsHTML/components/PaypalIcon";

const lngImg = {
    by: "https://bit.ly/bysolpaypal_by",
    ru: "https://bit.ly/paypabysol",
    en: "https://bit.ly/paypal_en",
}


const PaypalButton = ({name, bucket, currency = 'EUR', isImage = true, lng}) => {

    const business = process.env.REACT_APP_PAYPAL_BUSINESS;
    const hosted_button_id = process.env.REACT_APP_PAYPAL_BUTTON_ID;
    const formUrl = process.env.REACT_APP_PAYPAL_URL
    const imgUrl = lngImg[lng] || lng['en'];
    const formRef = useRef();
    const [hover, setHover] = useState(false)
    return (
        <form ref={formRef} action={formUrl} method="post" target={"_blank"}>
            <input type="hidden" id="business" name="business" value={business}/>
            <input type="hidden" id="custom" name="custom" value={bucket}/>
            <input type="hidden" id="hosted_button_id" name="hosted_button_id" value={hosted_button_id}/>
            <input type="hidden" id="cmd" name="cmd" value="_donations"/>
            <input type="hidden" id="item_name" name={name}/>
            <input type="hidden" id="currency_code" name={currency}/>
            <input type="hidden" name="charset" value="utf-8" />
            {isImage ? (
                <input type="image" height={52} src={imgUrl} border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            ) : (
                <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={ () => {
                    formRef.current.submit();
                }} className={'button button--l button--block button--outlined'}>
                    <PaypalIcon hover={hover}/>
                    <div className={'text'}>Paypal</div>
                </div>
            )}



            <img alt="" border="0" src="https://www.sandbox.paypal.com/ru_RU/i/scr/pixel.gif" width="1" height="1"/>
        </form>
    );
}

export default PaypalButton;
