import * as React from "react"

const DoneIcon = (props: any) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.907 6.844 2.605 8.147l3.907 3.907 6.512-6.512-1.302-1.302-5.21 5.21-2.605-2.606Z"
            fill="#fff"
        />
    </svg>
)

export default DoneIcon