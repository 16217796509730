interface ObjectCollection<T extends any> {
    [key: string]: T
}

export default <T extends any>(filterFn: (obj: T) => boolean, objectCollection: ObjectCollection<T>) => {
    return objectCollection ? Object.entries(objectCollection).reduce((filteredObjectCollection, [key, obj]): ObjectCollection<T> => {
        if (filterFn(obj)) {
            return {...filteredObjectCollection, [key]: obj}
        } else {
            return filteredObjectCollection;
        }
    }, {}) : {}
}