import * as React from 'react';
import './App.css';
import StripeHTML from "./components/StripeElementsHTML";
import {StripeProvider} from "./components/stripe/useStripe";
import {SubscriptionProvider} from "./components/StripeElementsHTML/components/subscription/SubscriptionWindow";

function App() {
    return (
        <StripeProvider>
            <SubscriptionProvider>
                <StripeHTML/>
            </SubscriptionProvider>
        </StripeProvider>
    );
}

export default App;
