import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import "./base.css";
import "./style.css";
import axios from "axios";
import useUrlParams from "../../utils/useURLParams";
import i18n, {supportedLang} from "../../i18n";
import {withNamespaces} from 'react-i18next';
import useStripe from "../stripe/useStripe";
import DonationFormV2 from "./components/DonationFormV2";
import {BucketProvider} from "./components/hooks/useBucket";
import {SubscriptionModal, useOpenSubscriptionModal} from "./components/subscription/SubscriptionWindow";

const Wrapper = styled.div`
  display: flex;

  .screen-shot {
    position: absolute;
    pointer-events: none;
    width: 380px;
    opacity: ${({imgVis}) => imgVis};
    left: 0;
    z-index: 22;
    //transition: opacity 0.5s ease-in;
  }
`

const uuid = () => {
    return 'bsl-xxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const values = {
    'q': 0.0001,
    'w': 0.2,
    'e': 0.6,
    'r': 1,
}

const StripeHTML = ({t}) => {
    const urlParams = useUrlParams();
    const {client} = useStripe();
    const [redirectId, setRedirectId] = useState(null)
    const lngPropsUrl = {...urlParams}.lng;
    const bysolId = uuid();
    const openSubscriptionModal = useOpenSubscriptionModal();

    useEffect(() => {
        if (supportedLang.includes(lngPropsUrl)) i18n.changeLanguage(lngPropsUrl);
        else i18n.changeLanguage("ru");
    }, [lngPropsUrl]);

    useEffect(() => {
        if (client && redirectId) {
            client.redirectToCheckout({sessionId: redirectId});
            setRedirectId(null)
        }
    }, [client])

    const onPriceSubmit = ({donation, support_team, currency, recaptcha, mode}) => {

        const onComplete = (subscribed = false) => {
            const description = (urlParams && urlParams.description) || 'Фонд Cолидарности Беларуси #BYSOL!';

            if (support_team) {
                support_team.name = urlParams.support_team_name || t('team_tips_amount')
                support_team.bucket = urlParams.support_team_bucket
                support_team.description = urlParams.support_team_description
            }else{
                support_team = undefined;
            }

            axios.post('/api/createInvoice', {
                stripeKey: "secret_2",
                subscribed: subscribed,
                recaptcha_token: recaptcha,
                amount: donation * 100,
                mode,
                currency: currency || "eur",
                support_team,
                description: description || "Фонд Cолидарности Беларуси #BYSOL",
                submitType: 'donate',
                bysolId,
                ...urlParams,
                success_url: urlParams.success_url ? (urlParams.success_url + `?clientId=${bysolId}`) : `https://bysol.org/spasibo?clientId=${bysolId}`,
                cancel_url: urlParams.cancel_url ? urlParams.cancel_url + `?clientId=${bysolId}` : `https://bysol.org/?clientId=${bysolId}`,
                name: urlParams ? urlParams.subtitle : 'Фонд Cолидарности Беларуси #BYSOL'
            }).then(
                async (response) => {
                    if (client) {
                        await client.redirectToCheckout({sessionId: response.data.id})
                    } else {
                        setRedirectId(response.data.id)
                    }

                }).catch(e => console.error(e.message))
        }

        openSubscriptionModal(onComplete);

    }

    return (
        <Wrapper className="globalContent">
            <BucketProvider bucketName={urlParams?.mbucket}>
                <DonationFormV2 onPriceSubmit={onPriceSubmit} {...urlParams}/>
                <SubscriptionModal />
            </BucketProvider>
        </Wrapper>
    )
}


export default withNamespaces()(StripeHTML);
