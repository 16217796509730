import * as React from "react";
import {useEffect, useState} from "react";
//@ts-ignore
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  //background: yellow;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .crypto-options{
    //background: blue;
    width: 100%;
    max-width: 380px;
    display: flex;
  }
  .support__confirmation-cancel{
    //background: red;
    display: flex;
    margin: 0;
    padding: 0;
    padding-top: 51px;
    align-self: center;
  }
`


const CryptoOptionsView =
    ({
         descriptionPart,
         selectCryptoText,
         bucket,
         setViewSelectedCrypto,
         returnBackText,
         setViewCryptoOptions
     }: any) => {
        return (
            <Wrapper>
                <div className="crypto-options">
                    {descriptionPart}
                    <p className="crypto-options__title">{selectCryptoText}</p>
                    <div className="crypto-options__list">
                        {Object.values(bucket.cryptoCurrencies).map((crypto: any) => {
                            const {address, icon, title} = crypto;
                            return (
                                <button
                                    className="crypto-button"
                                    key={address}
                                    onClick={() => {
                                        setViewSelectedCrypto(crypto)
                                    }}>
                                    <p className="crypto-button__title">{title}</p>
                                    <img
                                        className="crypto-button__logo"
                                        alt={`currency logo`}
                                        src={icon}/>
                                </button>
                            );
                        })}
                    </div>

                </div>
                <div className="support__confirmation-cancel">
                    <button
                        type="button"
                        onClick={() => setViewCryptoOptions(false)}
                    >
                        {returnBackText}
                    </button>
                </div>
            </Wrapper>
        )
    }

export default CryptoOptionsView