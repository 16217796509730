interface Props {
    donation: string,
    tips: string,
    currencyChar: string,
    totalChargeMonthText: string,
    isSubscription: boolean
    mode?: 'text' | 'html'
}

/**
 * Parses total amount on confirm screen
 * @param p {Props}
 */
export default (p: Props) => {
    const total = parseFloat(p.donation) + parseFloat(p.tips);
    const totalStr = total.toFixed(2)
    return p.mode == 'html' ? (
        p.isSubscription ?
            <div className={'total-amount__wrapper'}>
                <span
                    className={'total-amount__amount'}
                >
                    {p.currencyChar} {totalStr} /
                </span>
                <span
                    className={'total-amount__month_text'}>
                    {` ${p.totalChargeMonthText}`}*
                </span>
            </div> :
            <span className={'total-amount__wrapper'}>{p.currencyChar} {totalStr}</span>
    ) : (
        p.isSubscription ?
            `${p.currencyChar} ${totalStr} / ${p.totalChargeMonthText}*` :
            `${p.currencyChar} ${totalStr}`
    )
}