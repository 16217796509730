import {QRCodeSVG} from "qrcode.react";
import * as React from "react";
import {useEffect, useState} from "react";
//@ts-ignore
import styled from 'styled-components'
import DoneIcon from "./components/DoneIcon";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .selected-crypto .selected-crypto__address {
    outline: ${({copied}: any) => copied && `2px solid rgba(26, 144, 52, 1)`};
    border: ${({copied}: any) => copied && `1px solid transparent`};
    background: ${({copied}: any) => copied && `rgba(26, 144, 52, 0.1)`};
    transition: 0.25s ease-in;
  }

  .button--outlined {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({copied}: any) => copied ? `rgba(26, 144, 52, 0.8)` : ''};
    color: ${({copied}: any) => copied ? `white` : ''};
    border: ${({copied}: any) => copied ? `1px solid transparent !important` : ""};
    transition: all 0.1s ease-in;
  }

  .done-icon {
    opacity: ${({copied}: any) => copied ? 1 : 0};
    transform: translate(0, ${({copied}: any) => copied ? 0 : `5px`}) scale(${({copied}: any) => copied ? 1 : `0.2`});
    transition: all 0.3s ease-in;
    transition-delay: 0.2s;
  }
`


const SelectedCryptoCurrencyView =
    ({
         addressTitle = 'Aдрес кошелька',
         address,
         icon,
         bucketId,
         setClipboard,
         copyToClipboardText,
         copiedToClipboardText = "Скопировано в буффер",
         setViewSelectedCrypto,
         returnBackText,
     }: any) => {

        const [copied, setCopied] = useState(false)
        const [timeoutId, setTimeoutId] = useState<null | any>(null);
        return (
            // @ts-ignore
            <Wrapper copied={copied}>
                <div className={`selected-crypto`}>
                    <QRCodeSVG
                        value={address}
                        size={100}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        imageSettings={{
                            src: icon,
                            //@ts-ignore
                            x: null,
                            //@ts-ignore
                            y: null,
                            height: 24,
                            width: 24,
                            excavate: true,
                        }}
                    />
                    <p className="selected-crypto__address-title">{addressTitle} {bucketId?.toUpperCase()}:</p>
                    <textarea
                        className="selected-crypto__address"
                        readOnly
                        value={address}/>
                    <button
                        onClick={() => {
                            setCopied(true);
                            if (timeoutId) {
                                clearTimeout(timeoutId)
                            }
                            const id = setTimeout(() => setCopied(false), 7000)
                            setTimeoutId(id);
                            setClipboard(address)
                        }}
                        className="button button--s button--outlined">
                        <DoneIcon className="done-icon"/>
                        {!copied ? copyToClipboardText : copiedToClipboardText}
                    </button>
                </div>
                <div className="support__confirmation-cancel crypto">
                    <button
                        type="button"
                        onClick={() => {
                            setTimeout( () => {
                                if (timeoutId) {
                                    clearTimeout(timeoutId)
                                }
                                setCopied(false)
                            }, 500)

                            setViewSelectedCrypto(null)
                        }}>

                        {returnBackText}
                    </button>
                </div>
            </Wrapper>
        )
    }

export default SelectedCryptoCurrencyView