import React, {useEffect} from "react"
import gsap from "gsap";

const CryptoIcon = (props: React.SVGProps<SVGSVGElement> & {hover: boolean}) => {
    useEffect(() => {
        gsap.to('.c1', {duration: 0.5, x: props.hover ? -2 : 0, y: props.hover ? -2 : 0})
        gsap.to('.c2', {duration: 0.5, x: props.hover ? -2 : 0, y: props.hover ? 2 : 0})
        gsap.to('.c3', {duration: 0.5, x: props.hover ? 2 : 0, y: props.hover ? 2 : 0})
    }, [props.hover])
    return (
        //@ts-ignore
        <svg
            width={36}
            height={36}
            viewBox={'-2 -2 36 36'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g className={'c1'}>
                <circle cx={12.667} cy={10.667} r={10.667} fill="#8B92AF"/>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.667 21.333C6.775 21.333 2 16.558 2 10.667 2 4.775 6.775 0 12.667 0c5.891 0 10.666 4.775 10.666 10.667 0 5.891-4.775 10.666-10.666 10.666Zm5.329-10.52-4.997-8.146L8 10.813l4.999 2.902 4.997-2.902Zm.004.931-5.001 2.9L8 11.745l4.999 6.92L18 11.743Z"
                    fill="#8B92AF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.667 21.333C6.775 21.333 2 16.558 2 10.667 2 4.775 6.775 0 12.667 0c5.891 0 10.666 4.775 10.666 10.667 0 5.891-4.775 10.666-10.666 10.666Zm5.329-10.52-4.997-8.146L8 10.813l4.999 2.902 4.997-2.902Zm.004.931-5.001 2.9L8 11.745l4.999 6.92L18 11.743Z"
                    fill="#AEB7DC"
                />
                <path
                    d="M12.999 2.667V8.58l4.998 2.233-4.998-8.146Zm0 11.978v4.018L18 11.744l-5.001 2.901Z"
                    fill="#63698C"
                />
                <path d="m12.999 13.715 4.998-2.902-4.998-2.232v5.134Z" fill="#464B72"/>
                <path d="m8 10.813 4.999 2.902V8.581L8 10.813Z" fill="#63698C"/>
            </g>
            <path
                className={'c2'}
                d="M19.7 24.418c-1.335 5.36-6.766 8.619-12.118 7.282C2.222 30.364-1.037 24.934.3 19.582A9.994 9.994 0 0 1 12.419 12.3c5.352 1.328 8.618 6.758 7.282 12.118Z"
                fill="#E9973D"
            />
            <path
                className={'c2'}
                d="M14.547 20.754c.195-1.328-.813-2.047-2.203-2.524l.453-1.804-1.094-.274-.437 1.758c-.29-.07-.586-.14-.883-.203l.437-1.766-1.093-.273-.446 1.797c-.242-.055-.476-.11-.703-.164v-.008l-1.515-.375-.29 1.172s.813.187.797.195c.446.11.524.406.508.64l-.515 2.055c.03.008.07.016.117.04-.04-.008-.078-.016-.117-.032l-.72 2.875c-.054.133-.195.336-.5.258.009.016-.796-.195-.796-.195L5 25.184l1.43.359c.265.07.523.133.78.203l-.452 1.82 1.094.274.453-1.805c.297.078.593.156.875.227l-.446 1.797 1.094.273.453-1.82c1.875.351 3.281.21 3.867-1.485.477-1.359-.023-2.148-1.007-2.664.726-.164 1.265-.64 1.406-1.61Zm-2.508 3.515c-.336 1.36-2.633.626-3.375.438l.602-2.414c.742.188 3.132.555 2.773 1.977Zm.344-3.538c-.313 1.242-2.219.609-2.836.453l.547-2.188c.617.156 2.61.445 2.289 1.735Z"
                fill="#fff"
            />
            <g className={'c3'} >
                <circle cx={21.333} cy={18} r={10.667} fill="#fff"/>
                <path
                    d="M21.333 28.667c-5.891 0-10.666-4.776-10.666-10.667S15.442 7.333 21.333 7.333C27.225 7.333 32 12.11 32 18s-4.775 10.667-10.667 10.667Zm-2.589-11.731 2.59-2.59 2.59 2.591 1.507-1.506-4.098-4.098-4.096 4.096 1.507 1.507ZM14.667 18l1.506 1.507L17.68 18l-1.507-1.507L14.667 18Zm4.077 1.064-1.509 1.505.002.002 4.096 4.096 4.098-4.098-1.507-1.507-2.59 2.591-2.59-2.589ZM24.987 18l1.506 1.507L28 18l-1.507-1.507L24.987 18Zm-2.126-.001h.001l-1.529-1.528-1.13 1.129-.13.13-.267.268-.003.002.003.002 1.527 1.527 1.529-1.528v-.002Z"
                    fill="#EBBB4E"
                />
            </g>
        </svg>
    )

}
export default CryptoIcon
